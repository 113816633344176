/*{
    font-family: karma
} */
@import url('https://fonts.googleapis.com/css2?family=Copse&family=Coustard&family=Inter:wght@300&family=Karma:wght@300;400&display=swap');

.imageAndTextDiv {
    background-color: #F5F5F5;
    border-radius: 27px;
    margin: 15px 5px;
    padding: 25px 0px 0px 0px;
    width: 188px;
    height: 188px;
    align-self: center;
    position: relative;
}

/* .form-check-input1 {
    width: 1em !important;
    height: 1em !important;
    margin-top: .25em !important;
    vertical-align: top !important; */
/* background-color: #fff; */
/* background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
    border: 1px solid #22A3C8 !important;
    appearance: none !important;
    outline: none;
    border-radius: 100% !important;
} */

.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #ffffff00 !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 100% !important;
    appearance: none;
    outline: none;

}

/* .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e") !important
} */

.form-check-input:checked {
    outline: none !important;
    border-radius: 100% !important;
    background-color: #22A3C8 !important;
    border-color: #22A3C8 !important;
}

.form-check-input:focus {
    border-color: #86b7fe !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25)!important;
}

.Tost-position {
    top: 10px;
    right: 10px;
}

.position-of-button {
    position: sticky;
    bottom: 0;
}

.button-style-of-next {
    border: none;
    border-radius: 20px;
    height: 60px;
    width: 250px;
    font-size: 30px;
    font-family: 'Karma', serif;
}

.font-family{
    font-family: 'Karma', serif;
}

@media only screen and (max-width: 425px) {
    .imageAndTextDiv {
        background-color: #F5F5F5;
        border-radius: 27px;
        margin: 15px 5px;
        padding: 25px 0px 0px 0px;
        width: 188px;
        height: 188px;
        align-self: center;
        position: relative;
        font-size: 25px;
    }

    .form-check-input1 {
        width: 1em !important;
        height: 1em !important;
        margin-top: .25em !important;
        vertical-align: top !important;
        /* background-color: #fff; */
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: contain !important;
        border: 1px solid #22A3C8 !important;
        appearance: none !important;
        outline: none;
        border-radius: 100% !important;
    }

    .form-check-input1:checked {
        outline: none !important;
        border-radius: 100% !important;
        background-color: #22A3C8 !important;
        border-color: #22A3C8 !important;
    }
}