@import url('https://fonts.googleapis.com/css2?family=Copse&family=Coustard&family=Inter:wght@300&family=Karma:wght@300;400&display=swap');

.height-width {

    font-family: 'Karma', serif;
}

.btn {
    border: none !important;
    color: #fff !important;
    background-color: #22A3C8 !important;
    border-radius: 10px !important;
    width: 100% !important;
    /*height: 35px;
    text-align: center; */
}

.form-control {
    display: block;
    width: 100% !important;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media only screen and (min-width: 991px) {
    .width-m {
        width: 50%;
    }
}