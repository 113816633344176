@import url('https://fonts.googleapis.com/css2?family=Copse&family=Coustard&family=Inter:wght@300&family=Karma:wght@300;400&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    line-height: 24px;

}

.container {
    width: 1320px;
}

.mainbox3 {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
}

.mainbox {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.button-of-span {

    background: rgb(2, 0, 36);

    background: linear-gradient(198deg, rgba(2, 0, 36, 0) 0%, rgba(101, 180, 238, 1) 0%, rgba(11, 120, 199, 1) 100%);

    color: #fff;

    border: 2px solid rgb(253, 253, 253);

}

.loc img {
    width: 59px;
    height: 59px;
    /* margin-right: 15px; */
    margin: 5px 10px;
}

.add {
    font-size: 22px;
    color: #22A3C8;
    margin: 5px 0;
    font-family: 'Inter', sans-serif !important;
}

.add p {
    margin-bottom: 0;
}

.loction {
    color: #605E5E;
    font-size: 11px;
    font-weight: bold;
    margin: 5px 0;
}

.leftside {
    margin-left: 35px;
    /* margin-top: -25px; */
}
.xyz{
    justify-content: center;
    margin: 5px 5px;
}

@media screen and (max-width:425px) {
    body {
        font-size: 50% !important;
    }

    .logocont {
        margin-left: 25px !important;
    }

    .maping {
display: flex !important;
align-items: center !important;
justify-content: center !important;
    }

    .xyz {
        width: 100% !important;
    }

    .title-tex {
        font-size: 45px !important;
    }

    .title-tex2 {
        font-size: 20px !important;

    }

    .mainbox {
        min-width: 100% !important;

    }


}

@media screen and (max-width:1024px) {
    .leftside {
        width: 50%;
    }

    .maping {
        margin-top: 15px !important;
    }

    .btn {
        margin: 5px auto;
    }

    .mainbox {
        min-width: 100% !important;

    }
}

@media screen and (max-width:780px) {
    .leftside {
        width: 400px;
    }

    .maping {
        width: 30%;
    }
    .xyz{
        justify-content: center;
        margin: 5px auto !important;
    }    
    .mainbox {
        min-width: 100% !important;

    }
}

@media screen and (max-width:320px) {
    .leftside {
        width: 100%;
    }

    .maping {
        margin-left: 5px !important;
    }

    .mainbox {
        min-width: 100% !important;

    }
    .xyz{
        display: flex;
    }
}
/* @media screen and (max-width:734px) {

    .maping {
        
    }
} */

.INSTITUTE-OF-TECHNOLOGY {
    font-size: 30px;
    color: #22A3C8;
    font-family: 'Copse', serif;
    /* margin-top: -20px; */
}

.Software {
    font-family: 'Copse', serif;
    font-size: 70px;
    color: #22A3C8;
    /* margin-top: 50px !important; */
}

.Software1 {
    font-size: 35px;
    color: #22A3C8;
    font-family: 'Coustard', serif !important;
    font-weight: bold;
}
.Details{
    text-decoration: none;
}
.button-style-of-EnrollNow {
    border: none;
    border-radius: 20px;
    height: 60px;
    width: 250px;
    background: #22A3C8;
    color: #fff;
    font-size: 30px;
    font-family: 'Coustard', serif;
}