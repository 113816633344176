@import url('https://fonts.googleapis.com/css2?family=Copse&family=Coustard&family=Inter:wght@300&family=Karma:wght@300;400&display=swap');

.Box {
    /* height: 514px; */
    border-radius: 27px;
    background-color: #F5F5F5;
}

.Box-image-size {
    height: 91px;
    width: 91px;
}

.Box-Text-size {
    font-size: 30px;
}

.model-Icon {
    height: 108px;
    width: 108px;
    margin: 47px 0px 25px 0px;
}
.model-titel-text{
    font-size: 50px;
}
.btn-danger1 {
    --bs-btn-color: #fff !important;
    --bs-btn-bg: #22A3C8 !important;
    --bs-btn-border-color: #22A3C8 !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: #22A3C8 !important;
    --bs-btn-hover-border-color: #22A3C8 !important;
    --bs-btn-focus-shadow-rgb: 225,83,97 !important;
    --bs-btn-active-color: #fff !important;
    --bs-btn-active-bg: #22A3C8 !important;
    --bs-btn-active-border-color: #22A3C8 !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125) !important;
    --bs-btn-disabled-color: #fff !important;
    --bs-btn-disabled-bg: #22A3C8 !important;
    --bs-btn-disabled-border-color: #22A3C8 !important;
}
.Font-size-of-form{
    font-size: 15px;
}
.button-style-of-Submit {
    border: none;
    border-radius: 20px;
    height: 60px;
    width: 250px;
    background: #22A3C8;
    color: #fff;
    font-size: 30px;
    font-family: karma !important;
}
.font-family-form{
    font-family: 'Karma', serif;
}